import { useEffect } from "react"
import { useSelector } from "react-redux"
import useUser from "hooks/useUser"
import { useRouter } from "next/router"
import { selectUsersBlog } from "features/blogSlice"
import ConnectWalletInner from "./ConnectWalletInner"
import LoginEmailAndPassword from "./LoginEmailAndPassword"

/**
 * Helper function that prevents XSS attacks and open redirect
 * attacks, by only allowing relative URLs.
 */
function allowedToRedirect(url: string) {
  const relativeUrl = url.startsWith("/")
  return relativeUrl
}

export default function LoginForm() {
  const user = useUser()
  const blog = useSelector(selectUsersBlog)
  const router = useRouter()

  // When user is logged in, redirect to notes page.
  useEffect(() => {
    if (!user?.id || !blog.id) return

    // if the user needed to login to subscribe to a post, redirect them.
    if (
      router.query.redirect &&
      typeof router.query.redirect === "string" &&
      allowedToRedirect(router.query.redirect)
    ) {
      router.push(router.query.redirect)
      return
    }

    // user clicked "connect with wallet", so only redirect
    // them to setup if they haven't set up their blog yet.
    if ("wallet_address" in user && blog.needToSetup) {
      router.push("/setup")
      return
    }

    router.push("/notes")
    return
  }, [router, user, blog.id, blog.needToSetup])

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
        <div>
          <LoginEmailAndPassword />

          <ConnectWalletInner />
        </div>
      </div>
    </div>
  )
}
