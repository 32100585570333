import Logo from "../components/Logo"
import Head from "components/Head"
import LoginForm from "components/Login/LoginForm"
import CreateAccountInner from "components/Login/CreateAccountInner"

export default function Login() {
  return (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 sm:px-6 lg:px-8">
      <Head
        title="Login | Paragraph"
        description="Login to Paragraph"
        fullUrl="https://paragraph.xyz/login"
      />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex content-center">
          <Logo className="h-12 m-auto" />
        </div>
        <CreateAccountInner />
      </div>

      <LoginForm />
    </div>
  )
}
