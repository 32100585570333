import ConnectWalletBtn from "@/components/ConnectWalletButton"
import UnstoppableLogin from "components/UnstoppableLogin"
import { useEffect } from "react"
import { useAppDispatch } from "store"
import { getLoggedInUsersBlog } from "features/blogSlice"
import useUser from "hooks/useUser"

export default function ConnectWalletInner() {
  const dispatch = useAppDispatch()
  const user = useUser()

  useEffect(() => {
    if (!user?.id) return

    dispatch(getLoggedInUsersBlog())
  }, [user?.id, dispatch])

  return (
    <div className="mt-6">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">
            Or login with your crypto wallet
          </span>
        </div>
      </div>

      <div className="mt-6 grid">
        <div>
          <ConnectWalletBtn hideError />
        </div>
      </div>
      <UnstoppableLogin />
    </div>
  )
}
