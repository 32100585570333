import Link from "next/link"

export default function CreateAccountInner() {
  return (
    <div>
      <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
        Sign in to your account
      </h2>
      <p className="mt-2 text-sm text-center text-gray-600">
        Not a member yet?{" "}
        <Link href="/register" legacyBehavior>
          <button className="font-medium text-blue-600 hover:text-blue-500">
            Create an account
          </button>
        </Link>
      </p>
    </div>
  )
}
